var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import * as Sentry from '@sentry/browser';
import { overmind, connect } from '@/overmind';
import LoginForm from '@/components/SignIn/Form.vue';
import FullLoading from '@/components/Loadings/FullLoading.vue';
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        // resetPassword = false
        this.loading = false;
        this.pageLoading = false;
        this.errorKey = '';
    }
    async login(data) {
        this.cleanErrorKey();
        this.loading = true;
        // @ts-ignore TS2349: property inexistent
        this.$auth
            .login({
            data: {
                auth: {
                    email: data.email,
                    password: data.password,
                },
            },
        })
            .then(res => {
            this.pageLoading = true;
            this.loading = false;
            this.setLoginSession(res);
        })
            .catch(error => {
            this.loading = false;
            if (error &&
                error.response &&
                error.response.status &&
                error.response.status >= 400) {
                return this.setErrorKey('auth');
            }
            return this.setErrorKey('general');
        });
    }
    setLoginSession(res) {
        const data = res.data.data.attributes;
        if (res) {
            //@ts-ignore
            const currentUser = {
                id: res.data.data.id,
                onboardingRememberMe: data.onboarding_remember_me,
                name: data.name,
                email: data.email,
                role: data.role,
                featureGroup: data.feature_group,
                accountType: data.account_type,
                userInFirstMonth: data.user_in_first_month,
                hasOnboarding: data.has_onboarding,
                csEmail: data.account_cs ? data.account_cs.cs_email : '',
                csAvatarUrl: data.account_cs ? data.account_cs.cs_avatar : '',
                features: data.features,
                customer_id: data.customer_id,
                superlogica_id: data.superlogica_id,
                csgpt: data.csgpt,
                canReactivate: data.can_reactivate,
                showSpeedmailApp: data.show_speedmail_app,
            }; //@ts-ignore
            overmind.actions.users.setCurrentUser(currentUser);
        }
        const { name, email } = res.data.data.attributes;
        localStorage.removeItem('tokenPipeDrive');
        localStorage.removeItem('tokenPiperun');
        localStorage.removeItem('tokenAgendor');
        localStorage.removeItem('tokenPloomes');
        localStorage.removeItem('tokenSalesForce');
        localStorage.removeItem('tokenRdSTation');
        localStorage.removeItem('tokenHubSpot');
        localStorage.removeItem('urlBitrix24');
        localStorage.removeItem('integrationTokenToTvs');
        localStorage.removeItem('accessTokenToTvs');
        localStorage.removeItem('noShowAgainUpgradePlanModalPlg');
        localStorage.removeItem('tokenFunilDeVendas');
        localStorage.removeItem('codSellerFunilDeVendas');
        localStorage.removeItem('codSalesChannelFunilDeVendas');
        localStorage.removeItem('keyCodFunilDeVendas');
        localStorage.removeItem('counterClickToCopy');
        localStorage.removeItem('tokenZoho');
        localStorage.removeItem('closeBannerFastUpgradePlan');
        localStorage.removeItem('contractor_document');
        localStorage.removeItem('contractor_name');
        localStorage.removeItem('business_name');
        localStorage.removeItem('email');
        localStorage.removeItem('street_address');
        localStorage.removeItem('complemental_address');
        localStorage.removeItem('house_number');
        localStorage.removeItem('zipcode');
        localStorage.removeItem('neighborhood');
        localStorage.removeItem('city');
        localStorage.removeItem('state');
        // @ts-ignore
        window.jivo_api.setContactInfo({ name, email });
        Sentry.setUser({ email: email, username: name });
    }
    setErrorKey(field) {
        return (this.errorKey = field);
    }
    cleanErrorKey() {
        return (this.errorKey = '');
    }
    async setTokenByParams() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token');
        if (token) {
            this.loading = true;
            localStorage.setItem('auth_token_default', token);
            //@ts-ignore
            const refresh_token = await this.users.getUserRefreshToken(token);
            if (refresh_token.status == 200) {
                this.pageLoading = true;
                this.loading = false;
                localStorage.setItem('auth_token_default', refresh_token.data.jwt);
                //@ts-ignore
                const me = await this.users.getUserMeByToken();
                this.setLoginSession(me);
                window.location.href = '/';
                return;
            }
            this.pageLoading = false;
            this.loading = false;
            localStorage.setItem('auth_token_default', '');
            return this.setErrorKey('auth');
        }
    }
    async mounted() {
        this.setTokenByParams();
    }
};
Login = __decorate([
    Component(connect(({ actions }) => ({
        users: actions.users,
    }), {
        name: 'Login',
        components: {
            Input,
            LoginForm,
            FullLoading,
        },
    }))
], Login);
export default Login;
