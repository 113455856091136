import { render, staticRenderFns } from "./UserSendEmailToPasswordContainer.vue?vue&type=template&id=58a2d389&scoped=true&"
import script from "./UserSendEmailToPasswordContainer.vue?vue&type=script&lang=ts&"
export * from "./UserSendEmailToPasswordContainer.vue?vue&type=script&lang=ts&"
import style0 from "./UserSendEmailToPasswordContainer.vue?vue&type=style&index=0&id=58a2d389&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58a2d389",
  null
  
)

export default component.exports