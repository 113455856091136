var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import IconSpeedioSpinner from '@/components/Icons/IconSpeedioSpinner.vue';
import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { isEmpty } from 'lodash';
import Input from 'components/Input.vue';
import { isEmail } from '@/shared/utils/helpers';
let SendEmailToPasswordContainer = class SendEmailToPasswordContainer extends Vue {
    constructor() {
        super(...arguments);
        this.email = '';
        this.loading = false;
        this.error = false;
    }
    fieldsFilledReset() {
        if (!isEmpty(this.email))
            return true;
        return false;
    }
    showNotify(message, type) {
        this.$q.notify({
            message: message,
            type: type,
        });
    }
    async confirm() {
        if (isEmail(this.email)) {
            this.loading = true;
            // @ts-ignore TS2349: property inexistent
            await this.actions.resetPassword({ email: this.email });
            if (
            // @ts-ignore TS2349: property inexistent
            this.state.statusResponse.code === 201 &&
                // @ts-ignore TS2349: property inexistent
                this.state.statusResponse.type === 'reset-password') {
                this.email = '';
                this.loading = false;
                this.successRecovery();
                this.showNotify('As informações foram enviadas para o seu e-mail.', 'success-email');
            }
            if (
            // @ts-ignore TS2349: property inexistent
            this.state.statusResponse.code === 404 &&
                // @ts-ignore TS2349: property inexistent
                this.state.statusResponse.type === 'reset-password') {
                this.recoveryError();
                this.loading = false;
                this.error = true;
            }
        }
    }
    resetError() {
        this.error = false;
    }
    recoveryError() {
        return 'recoveryPass';
    }
    successRecovery() {
        return true;
    }
    mounted() {
        this.$gtag.pageview({ page_path: this.$route.path });
    }
};
__decorate([
    Watch('email'),
    Emit('resetError')
], SendEmailToPasswordContainer.prototype, "resetError", null);
__decorate([
    Emit('recoveryError')
], SendEmailToPasswordContainer.prototype, "recoveryError", null);
__decorate([
    Emit('successRecovery')
], SendEmailToPasswordContainer.prototype, "successRecovery", null);
SendEmailToPasswordContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        name: 'SendEmailToPasswordContainer',
        components: { Input, IconSpeedioSpinner },
    }))
], SendEmailToPasswordContainer);
export default SendEmailToPasswordContainer;
