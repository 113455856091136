var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import SendEmailToPasswordContainer from '@/modules/users/containers/UserSendEmailToPasswordContainer.vue';
import IconLogoSpeedio from '../Icons/IconLogoSpeedio.vue';
import IconEye from '../Icons/iconEye.vue';
import IconSpeedioSpinner from '../Icons/IconSpeedioSpinner.vue';
import Alert from '../SignIn/Alert.vue';
let SignInForm = class SignInForm extends Vue {
    constructor() {
        super(...arguments);
        this.showPass = false;
        this.btnLoading = false;
        this.showPassRecovery = false;
        this.data = {
            email: '',
            password: '',
        };
        this.errorList = {
            auth: {
                message: 'E-mail ou senha estão incorretos.',
            },
            emptyFields: {
                message: 'Os campos E-mail e senha devem estar preenchidos.',
            },
            recoveryPass: {
                message: 'Verifique se o e-mail digitado está correto e tente novamente!',
            },
            general: {
                message: 'Erro inesperado, entre em contato com o suporte.',
            },
        };
        this.errorField = '';
    }
    openRecovery() {
        return (this.showPassRecovery = true);
    }
    closeRecovery() {
        return (this.showPassRecovery = false);
    }
    handleShowPass() {
        return (this.showPass = !this.showPass);
    }
    handleSignIn() {
        this.btnLoading = true;
        if (!this.data.email || !this.data.password) {
            return this.setErrorField('emptyFields');
        }
        else {
            return this.emitUserData();
        }
    }
    emitUserData() {
        return this.data;
    }
    resetError() {
        return (this.errorField = '');
    }
    setErrorField(field = this.errorKey) {
        this.disableBtnLoading();
        return (this.errorField = field);
    }
    setLoading() {
        return (this.btnLoading = this.loading);
    }
    disableBtnLoading() {
        return (this.btnLoading = false);
    }
};
__decorate([
    Prop({ default: false })
], SignInForm.prototype, "incompatibleMode", void 0);
__decorate([
    Prop({ default: '' })
], SignInForm.prototype, "errorKey", void 0);
__decorate([
    Prop({ default: false })
], SignInForm.prototype, "loading", void 0);
__decorate([
    Emit('data')
], SignInForm.prototype, "emitUserData", null);
__decorate([
    Watch('data.email'),
    Watch('data.password')
], SignInForm.prototype, "resetError", null);
__decorate([
    Watch('errorKey')
], SignInForm.prototype, "setErrorField", null);
__decorate([
    Watch('loading')
], SignInForm.prototype, "setLoading", null);
SignInForm = __decorate([
    Component({
        name: 'SignInForm',
        components: {
            SendEmailToPasswordContainer,
            IconLogoSpeedio,
            IconEye,
            IconSpeedioSpinner,
            Alert,
        },
    })
], SignInForm);
export default SignInForm;
